<template>
  <header class="header">
    <!--start navbar-->
    <nav
      id="barr"
      class="navbar navbar-expand-lg navColor1 fixed-top"
      style="
        background-image: linear-gradient(136deg, #f8f8ff, #f8f8ff) !important;
      "
      v-bind:class="{
        affix: hasAffix,
        'custom-nav': coloredLogo,
        'bg-transparent': !coloredLogo,
        'white-bg': coloredLogo,
      }"
    >
      <div class="container">
        <a
          class="navbar-brand"
          href="/priceline-visa-presents-a-virtual-cooking-experience-with-chef-michael-mina-and-the-wine-mvp-will-blackmon"
          ><img
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/PricelineVisa_navLogo_V1.png"
            width="179"
            alt="logo"
            class="img-fluid"
        /></a>

        <button
          class="navbar-toggler"
          type="button"
          @click="mobileNavClicked"
          v-bind:class="{ collapsed: collapsed }"
        >
          <span class="ti-menu"></span>
        </button>

        <div
          class="collapse navbar-collapse main-menu h-auto"
          v-bind:class="{ show: !collapsed }"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav ml-auto">
            <li class="nav-item nav-link page-scroll">
              <router-link
                id="newnav"
                to="/priceline-visa-presents-a-virtual-cooking-experience-with-chef-michael-mina-and-the-wine-mvp-will-blackmon"
                ><span
                  n
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-size: 1.2em;
                    font-weight: 500;
                    color: #003c8a;
                  "
                  ><i class="fa fa-home" aria-hidden="true"></i> Home</span
                ></router-link
              >
            </li>

            <li class="nav-item nav-link page-scroll">
              <router-link
                id="newnav"
                class="nav-link"
                to="/priceline-visa-presents-a-virtual-cooking-experience-with-chef-michael-mina-and-the-wine-mvp-will-blackmon-travel-guide"
              >
                <span
                  n
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-size: 1.2em;
                    font-weight: 500;
                    color: #003c8a;
                  "
                  ><i class="fa fa-plane" aria-hidden="true"></i> Priceline
                  Travel</span
                >
              </router-link>
            </li>

            <li class="nav-item nav-link page-scroll">
              <router-link
                id="newnav"
                to="/priceline-visa-presents-a-virtual-cooking-experience-with-chef-michael-mina-and-the-wine-mvp-will-blackmon-faqs"
                ><span
                  n
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: #003c8a;
                  "
                  ><i class="fa fa-question-circle" aria-hidden="true"></i>
                  FAQs</span
                ></router-link
              >
            </li>

            <li class="nav-item nav-link page-scroll">
              <router-link
                id="newnav"
                to="/priceline-visa-presents-a-virtual-cooking-experience-with-chef-michael-mina-and-the-wine-mvp-will-blackmon"
                style="opacity: 1"
                ><span
                  @click="valclicked"
                  class="btn solid-btn-SWA text-center"
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-size: 0.9em;
                    padding-top: 5px;
                    padding-right: 12px;
                    padding-bottom: 5px;
                    padding-left: 12px;
                    opacity: 1;
                  "
                >
                  REGISTER NOW</span
                ></router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!--end navbar-->
  </header>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    coloredLogo: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      windowTop: 0,
      collapsed: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, {
      passive: true,
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    valclicked() {
      window.showpopup = true;
      this.$emit("opencart", true);
    },
    onScroll: function () {
      this.windowTop = window.top.scrollY;
    },
    mobileNavClicked: function () {
      this.collapsed = !this.collapsed;
    },
  },
  computed: {
    hasAffix: function () {
      return this.windowTop > 0;
    },
  },
};
</script>

<style scoped>
.navbar-toggler {
  background: #0068ef;
  padding: 0.5rem 0.75rem;
  font-size: inherit;
  transition: all 0.3s ease-in-out;
}
</style>
