<template>
  <div style="background-color: white">
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Phone"
                v-model="phoneNo"
              />
            </div>
            <div
              class="col-md-6"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode !== 'tk_pX0Lp69INGHCNiw'
              "
            >
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Business Name (optional)"
                v-model="bname"
              />
            </div>
            <div
              class="col-md-6 mb-1"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode !== 'tk_pX0Lp69INGHCNiw'
              "
            >
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                (TicketsResult[selected] || {}).ticketCode !=
                  'tk_pX0Lp69INGHCNiw'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.table"
                  v-for="seating in uniq(
                    (TicketsResult[selected] || {}).availableSeating
                  )"
                  :value="seating"
                >
                  {{ seating }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                (TicketsResult[selected] || {}).ticketCode ==
                  'tk_ZNDRjDCAnOGEjAn'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.table"
                  v-for="seating in uniq1(
                    (TicketsResult[selected] || {}).availableSeating
                  )"
                  :value="seating"
                >
                  {{ seating }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div
              class="col-md-12 mt-2"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_pX0Lp69INGHCNiw'
              "
            >
              <doublelist
                @listtopush="listtopush"
                v-if="
                  TicketsResult[selected] &&
                  TicketsResult[selected].availableSeating.length > 0
                "
                :list="(TicketsResult[selected] || {}).availableSeating"
                :limit="(TicketsResult[selected] || {}).allowedPerRequest || 0"
              />
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  (TicketsResult[selected] &&
                  groupData.includes(TicketsResult[selected].ticketCode)
                    ? !required_power_needs.trim().length
                    : false) ||
                  !fullname.trim().length ||
                  !phoneNo.trim().length ||
                  !invalidemail ||
                  !invalidphone ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  (TicketsResult[selected] &&
                  TicketsResult[selected].ticketCode == 'tk_pX0Lp69INGHCNiw'
                    ? selectionList.length < 1
                    : false)
                "
                class="btn solid-btn-TNT m-auto mr-2"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_Tuxedos_and_Tennis_Shoes_Annual_Gala.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background: #000011; border-top: 1px solid white"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  color: white;
                "
              >
                Tuxedos and Tennis Shoes Annual Gala
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 1.2rem;
                  color: white;
                "
              >
                Saturday, October 26, 2024, <br />
                06:00pm - 11:00pm
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 1.2rem;
                  color: white;
                "
              ></h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 1.8rem;
                  color: black;
                "
              >
                Diamond Achievers Foundation of Frisco presents The 3rd Annual
                Tuxedos & Tennis Shoes Gala.
              </p>
            </div>

            <div class="download-content mt-2">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 1.4rem;
                  color: black;
                "
              >
                <span class="bolded"> Event Details:</span>
                <br />
                📅 Date: Saturday, October 26, 2024<br />
                ⏰Time: 6:00PM - 11:00PM
                <br />
                📍Location: 3800 Gaylord Pkwy, Frisco, TX 75034, USA
                <br />
              </p>
              <hr />

              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 1.4rem;
                  color: black;
                "
              >
                🗣️For a seamless ticketing and check-in experience, we've
                partnered with MYLE - Make Your Life Entertaining! MYLE is a
                Black Owned all-in-one AI-based event and entertainment
                platform.

                <br />
                <br />

                Download the MYLE Events App via
                <a
                  href="https://myle.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.myle.com</a
                >
                or find it in your iOS or Google Play Store under MYLE Events.

                <br />
                <br />
                Note: Your tickets will be available on the MYLE App in the
                Account>Tickets section after registration. You can easily
                access them via email (check spam if not received) or upload
                them to your phone wallet for quick access into the event.

                <br />
                <br />

                The MYLE App offers convenient transportation options,
                integrating Waze, Apple Maps, Google Maps, Uber, and Lyft. It's
                your one-stop solution for a hassle-free travel experience.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section ends -->

      <div
        id="d2"
        style="max-width: 700px; margin: auto; height: auto; margin-top: 50px"
      ></div>

      <div
        class="row align-items-center justify-content-center test"
        style="margin: auto; margin-top: 20px"
      >
        <div
          class="col-md-2 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: yellow;
                      color: yellow;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                    "
                  >
                    General Admission - $175
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="col-md-2 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: #1434a4;
                      color: #1434a4;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                    "
                  >
                    Diamond Level - $15,000
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="col-md-2 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: #1434a4;
                      color: #1434a4;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                    "
                  >
                    Platinum Level - $10,000
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="col-md-2 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: #1434a4;
                      color: #1434a4;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                    "
                  >
                    Creme Level - $5,000
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="col-md-2 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: red;
                      color: red;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                    "
                  >
                    Crimson Level - $2,000
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="col-md-2 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: lightgrey;
                      color: lightgrey;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                    "
                  >
                    Reserved
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br /><br />

      <div class="section-heading mb-2 mt-1">
        <div class="text-center">
          <a
            href="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/DAF_TNT-Sponsorship-Package.pdf"
            class="btn solid-btn-TNT text-center"
            target="_blank"
            >Sponsorship Deck
          </a>
        </div>
      </div>

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                "
              >
                TICKET OPTIONS
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in individualTick(TicketsResult)"
              >
                <div
                  class="card-header MYLE-green white"
                  style="
                    background: #000000;
                    color: white;
                    width: 310px !important;
                  "
                >
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <!-- <p
                    v-bind:key="w"
                    v-for="(msgs, w) in (tkList[obj.ticketCode] || []).messages"
                  >
                    - {{ msgs }}
                  </p> -->
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn-TNT"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <!-- <div class="row w-100 m-0 promo-section">
      <div class="col-lg-12 col-md-12 mt-5">
        <div class="mt-3">
          <div class="section-heading mb-5">
            <h2
              class="text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 15px;
              "
            >
              Powered by <a target="_blank" href="https://myle.com/">MYLE</a>
            </h2>
          </div>
        </div>
      </div>
    </div> -->
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import Doublelist from "./doubleList.vue";

// tslint-disable no-mixed-spaces-and-tabs
// defining component state
export default {
  name: "newyear",
  data() {
    return {
      options: {
        text: {
          color: "black",
          fontSize: 10,
          textAdjustmentY: 2,
          fontFamily: "Arial",
          textEnabled: true,
        },
        thermo: {
          color: "#145143",
          backgroundColor: "#ffffff",
          frameColor: "black",
          ticks: 10,
          ticksEnabled: true,
          tickColor: "black",
          tickWidth: "1",
        },
        layout: {
          height: 300,
          width: 90,
        },
      },
      selectionList: [],
      dict: {},
      dictlist: {},
      required_power_needs: "No",
      selectedSizes: "Extra Small - XS",
      availSeating: "",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      individualsData: [
        "tk_pX0Lp69INGHCNiw",
        "tk_V5oXHp3DhGTk5Tu",
        "tk_jay1QUDhTlzRvws",
        "tk_rfrhYXdNpy8KMze",
        "tk_c9sI6URakcKvxLN",
      ],

      groupData: [],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_pX0Lp69INGHCNiw: {
          messages: ["Ticket Price: $175"],
        },

        tk_V5oXHp3DhGTk5Tu: {
          messages: ["Ticket Price: $15000"],
        },

        tk_jay1QUDhTlzRvws: {
          messages: ["Ticket Price: $10000"],
        },

        tk_rfrhYXdNpy8KMze: {
          messages: ["Ticket Price: $5000"],
        },

        tk_c9sI6URakcKvxLN: {
          messages: ["Ticket Price: $2000"],
        },
      },

      priceIds: {
        tk_pX0Lp69INGHCNiw: "price_1PpNUSD0vTZ4QB9cxeQRloVc",
        tk_V5oXHp3DhGTk5Tu: "price_1PpNUYD0vTZ4QB9cMPID1NBK",
        tk_jay1QUDhTlzRvws: "price_1PpNUfD0vTZ4QB9cYvjyFaeE",
        tk_rfrhYXdNpy8KMze: "price_1PpNUsD0vTZ4QB9cMCWcrVmL",
        tk_c9sI6URakcKvxLN: "price_1PpNV1D0vTZ4QB9cFYc7Nyzi",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      bname: "",
      email: "",
      phoneNo: "",
      tickcount: 1,
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    Doublelist,
    StripeCheckout,
  },
  methods: {
    listtopush(rr) {
      this.selectionList = [...rr];
    },
    uniq(list) {
      if (list && list.length > 0) {
        const uniqValues = {};
        list.forEach((v) => {
          uniqValues[v.table] = true;
        });

        return Object.keys(uniqValues);
      }

      return [];
    },
    uniq1(list) {
      if (list && list.length > 0) {
        const uniqValues = [];
        list.forEach((v) => {
          if (
            v.seat.split("Seat ")[1] == "1" ||
            v.seat.split("Seat ")[1] == "6"
          ) {
            uniqValues.push(
              v.table +
                " " +
                "Seat " +
                (v.seat.split("Seat ")[1] == "1" ? "1 to 5" : "6 to 10")
            );
          }
        });

        return uniqValues;
      }

      return [];
    },
    returnColor(v) {
      const vm = this;
      if (vm.dictlist[v]) {
        if (vm.dictlist[v] == "#ffff00") {
          return "#ffff00";
        } else {
          return vm.dictlist[v];
        }
      } else {
        return "lightgrey";
      }
    },
    datad2(listdata) {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };

      const zx = [];
      const zobj = {
        0: {
          texts: [28, 27, 13, 12, 1],
          x: 70,
          y: 145,
          count: 5,
        },
        1: {
          texts: [43, 29, 26, 14, 11, 2],
          x: 140,
          y: 70,
          count: 6,
        },
        2: {
          texts: [42, 30, 25, 15, 10, 3],
          x: 210,
          y: 70,
          count: 6,
        },
        3: {
          texts: [41, 31, 24],
          x: 280,
          y: 70,
          count: 3,
        },
        4: {
          texts: [40, 32, 23],
          x: 350,
          y: 70,
          count: 3,
        },
        5: {
          texts: [39, 33, 22],
          x: 420,
          y: 70,
          count: 3,
        },
        6: {
          texts: [38, 34, 21, 16, 9, 4],
          x: 490,
          y: 70,
          count: 6,
        },
        7: {
          texts: [37, 35, 20, 17, 8, 5],
          x: 560,
          y: 70,
          count: 6,
        },
        8: {
          texts: [36, 19, 18, 7, 6],
          x: 630,
          y: 145,
          count: 5,
        },
      };
      for (let j = 0; j < 9; j++) {
        for (let k = 0; k < zobj[j].count; k++) {
          zx.push({
            x: zobj[j].x,
            y: zobj[j].y + k * 80,
            val: seat[8],
            distance: 10,
            ydistance: 10,
            text: zobj[j].texts[k],
          });
        }
      }

      let recs = [];
      this.dict = {};
      this.dictlist = {};

      listdata.forEach((r) => {
        r.availableSeating.forEach((r1) => {
          if (r.ticketCode == "tk_c9sI6URakcKvxLN") {
            // "Crimson Level - $2,000" red
            this.dictlist[r1.table.split("Table ")[1]] = "#ff0000";
          }

          if (r.ticketCode == "tk_rfrhYXdNpy8KMze") {
            // "Creme Level - $5,000" blue
            this.dictlist[r1.table.split("Table ")[1]] = "#1434a4";
          }

          if (r.ticketCode == "tk_jay1QUDhTlzRvws") {
            // "Platinum Level - $10,000" blue
            this.dictlist[r1.table.split("Table ")[1]] = "#1434a4";
          }

          if (r.ticketCode == "tk_V5oXHp3DhGTk5Tu") {
            // "Diamond Level - $15,000" blue
            this.dictlist[r1.table.split("Table ")[1]] = "#1434a4";
          }

          if (r.ticketCode == "tk_pX0Lp69INGHCNiw") {
            // "General Admission - $175" yellow
            this.dictlist[r1.table.split("Table ")[1]] = "#ffff00";
          }
        });

        recs = [...recs, ...r.availableSeating];
      });

      recs.forEach((r) => {
        const tableRecord = r.table.split("Table ")[1];
        const seatRecord = r.seat.split("Seat ")[1];

        if (!this.dict[tableRecord]) {
          this.dict[tableRecord] = [];
        }

        this.dict[tableRecord].push(Number(seatRecord) - 1);
      });

      return [...zx];
    },
    drawseatsdd(listdata) {
      const vm = this;
      const svg = window.d3
        .select("div#d2")
        .append("svg")
        .attr("width", "700px")
        .attr("height", "600px");
      const innerCircleRadius = 15;
      const outerCircleRadius = 15;
      const outerCircleRadius1 = 15;

      this.datad2(listdata).forEach((v) => {
        const originX = v.x + v.distance;
        const originY = v.y + v.ydistance;

        svg.append("circle").attr({
          cx: originX,
          cy: originY,
          r: innerCircleRadius,
          fill: v.selected ? "yellow" : "none",
          stroke: "black",
        });

        svg
          .append("text")
          .attr({
            x:
              originX -
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 12 : 9) : 3),
            y:
              originY +
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 5 : 5) : 5),
            "font-size": 15,
          })
          .text(v.text);

        for (let i = 0; i < 1200; i += v.val) {
          const chairOriginX1 =
            originX - 3 + (outerCircleRadius1 + 16) * Math.sin(i);
          const chairOriginY1 =
            originY + 2 - (outerCircleRadius1 + 16) * Math.cos(i);
          const chairOriginX = originX + (outerCircleRadius + 5) * Math.sin(i);
          const chairOriginY = originY - (outerCircleRadius + 5) * Math.cos(i);

          svg.append("circle").attr({
            cx: i == 1200 ? Math.ceil(chairOriginX + 1) : chairOriginX,
            cy: chairOriginY,
            r: 4,
            fill:
              vm.dict[v.text] &&
              vm.dict[v.text].includes(i > 0 ? Math.ceil(i / v.val) : 0)
                ? vm.returnColor(v.text)
                : "lightgrey",
          });

          svg
            .append("text")
            .attr({
              x: chairOriginX1,
              y: chairOriginY1,
              stroke: "black",
              "font-size": 10,
            })
            .text(() => {
              return i > 0 ? Math.ceil(i / v.val) + 1 : 1;
            });
        }
      });
      /// rects
      svg
        .append("rect")
        .attr("width", 200)
        .attr("height", 200)
        .attr("x", 260)
        .attr("y", 290)
        .attr("fill", "none")
        .attr("stroke", "black");
      svg
        .append("text")
        .attr("x", 340)
        .attr("y", 400)
        .text("")
        .attr("fill", "none")
        .attr("stroke", "black");

      svg
        .append("rect")
        .attr("width", 200)
        .attr("height", 90)
        .attr("x", 260)
        .attr("y", 500)
        .attr("fill", "none")
        .attr("stroke", "black");
      svg
        .append("text")
        .attr("x", 340)
        .attr("y", 550)
        .text("Stage")
        .attr("fill", "none")
        .attr("stroke", "black");
    },
    individualTick(list) {
      return list.filter((t) => this.individualsData.includes(t.ticketCode));
    },
    groupTickets(list) {
      return list.filter((t) => this.groupData.includes(t.ticketCode));
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.fullname = "";
      this.phoneNo = "";
      this.bname = "";
      this.email = "";
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });

      this.redeemData = null;
      this.selectionList = [];
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0] &&
        this.TicketsResult[idx].ticketCode != "tk_pX0Lp69INGHCNiw"
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].table;
      } else {
        this.availSeating = "";
      }
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      if (vm.groupData.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        payloadToPush.extraInfo["required_power_needs"] =
          vm.required_power_needs;
      }

      if (vm.availSeating.length > 0) {
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: this.availSeating,
        };
      }

      if (vm.TicketsResult[vm.selected].ticketCode == "tk_pX0Lp69INGHCNiw") {
        const seatRecord = [];
        this.selectionList.forEach((r) => {
          seatRecord.push(r.alias);
        });
        payloadToPush.totalTickets = seatRecord.length;
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: seatRecord,
        };
      }

      if (vm.TicketsResult[vm.selected].ticketCode != "tk_pX0Lp69INGHCNiw") {
        const seatsArr = [];
        const initalval = this.availSeating;
        seatsArr.push(initalval + " - Seat 1");
        seatsArr.push(initalval + " - Seat 2");
        seatsArr.push(initalval + " - Seat 3");
        seatsArr.push(initalval + " - Seat 4");
        seatsArr.push(initalval + " - Seat 5");
        seatsArr.push(initalval + " - Seat 6");
        seatsArr.push(initalval + " - Seat 7");
        seatsArr.push(initalval + " - Seat 8");
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: seatsArr,
        };
      }

      payloadToPush.extraInfo = {
        business_name: this.bname.trim(),
        phone: this.phoneNo,
      };

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets");
      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.isSubmitted = false;
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      const payloadToPush = {
        eventId: "gfAZfeaGehGcOC",
      };
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredEventTicketsWithSeating",
          payloadToPush
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_pX0Lp69INGHCNiw: null,
            tk_V5oXHp3DhGTk5Tu: null,
            tk_jay1QUDhTlzRvws: null,
            tk_rfrhYXdNpy8KMze: null,
            tk_c9sI6URakcKvxLN: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
          vm.drawseatsdd(vm.eventTicketResult);
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phoneNo || "").match(/\d/g) &&
        (this.phoneNo || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - Tuxedos and Tennis Shoes Annual Gala",

    meta: [
      {
        name: "description",
        content: "MYLE - Tuxedos and Tennis Shoes Annual Gala",
      },

      {
        name: "keywords",
        content: "MYLE - Tuxedos and Tennis Shoes Annual Gala",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/tuxedos-and-tennis-shoes-annual-gala",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}

@media (max-width: 922px) {
  #d2 {
    overflow-x: scroll;
  }
}
@media (min-width: 922.1px) {
  #d2 {
    overflow-x: hidden;
  }
}

.cred {
  background: #c07f00 !important;
  border: 1px solid #c07f00 !important;
}

.cgreen {
  background: #0ea293;
  border: 1px solid #0ea293 !important;
}

.cgold {
  background: #a19140;
  border: 1px solid #a19140 !important;
}

.bolded {
  font-weight: bold;
}
</style>
