<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal12">
      <confirmationPopup :showsuccess="showsuccess" @closemodal="closeModal" />
    </nu-modal>

    <nu-modal ref="modal">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div
              class="col-md-6 mb-1"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode !== 'tk_CV9FypVc9e4SYFI'
              "
            >
              <label class="mt-3">Number of Section(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode !== 'tk_CV9FypVc9e4SYFI'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.alias"
                  v-for="seating in uniq(
                    (TicketsResult[selected] || {}).availableSeating
                  )"
                  :value="seating"
                >
                  {{ seating }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn-crimson btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div
              class="col-md-12 mt-2"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_CV9FypVc9e4SYFI'
              "
            >
              <doublelist
                @listtopush="listtopush"
                v-if="
                  TicketsResult[selected] &&
                  TicketsResult[selected].availableSeating.length > 0
                "
                :list="(TicketsResult[selected] || {}).availableSeating"
                :limit="(TicketsResult[selected] || {}).allowedPerRequest || 0"
              />
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  (TicketsResult[selected] &&
                  groupData.includes(TicketsResult[selected].ticketCode)
                    ? !required_power_needs.trim().length
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  (TicketsResult[selected].ticketCode == 'tk_ktWG5PPojiUDOA0'
                    ? !redeemData
                    : false) ||
                  (TicketsResult[selected] &&
                  TicketsResult[selected].ticketCode == 'tk_CV9FypVc9e4SYFI'
                    ? selectionList.length < 1
                    : false)
                "
                class="btn solid-btn-crimson m-auto mr-2"
                id="reserve"
                style="pointer-events: all; cursor: pointer"
              >
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_TheBlueTigerBisonBoogie_V2.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background: #ac0910"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  color: white;
                "
              >
                The Big Blue & HU: Official Tennessee State Alumnus Party
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: white;
                "
              >
                Thursday October 17, 2024 <br />
                8:00pm - 2:00am
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: white;
                "
              >
                <a
                  href="https://maps.app.goo.gl/M1hMvRfcQTvqf5Dq5"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: white"
                >
                  Lima Twist
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Welcome to the Big Blue & HU: Official Tennessee State Alumnus
                Partyin conjunction with the 100 Year Celebration of Howard
                Homecoming. Join us for succulent bites, spirits and soundscapes
                by DJ C-Lo & DJ Spoon,

                <br /><br />

                <span class="bolded" style="font-size: 24px">
                  Event Details:</span
                >
                <br />
                📅 Date: Thursday October 17, 2024
                <br />
                ⏰ Time: 8:00pm-2:00am (EST)<br />
                📍 Location: Lima Twist | 1411 K St NW, Washington, DC 20005,
                United States
                <br />
                🚗 Valet Parking Available<br />
                🎶 Soundscapes by DJ C-Lo Tenn State Alum + DJ Spoon HU ALum

                <br /><br />

                <span class="bolded" style="font-size: 24px"> Pricing:</span
                ><br />

                Tenn State Alumni Discounted Tickets = $19.12<br />
                Howard Alumni Discounted Tickets= $18.67<br />
                General Admission Tickets= $20.00<br />
                Extended General Admission Tickets= $30.00<br />
                <br />

                <span class="bolded" style="font-size: 18px"> Tables</span>
                <br />
                Tables 101 - 209 = $100 Requires Reservation Fee with a $200
                minimum (Seats 4)<br />
                Tables 301 - 307 = $125 Requires Reservation Fee with a $400
                minimum (Seats 6)<br />
                Tables 401 - 403 = $150 Requires Reservation Fee with a $500
                minimum (Seats 8)

                <br /><br />

                🗣️For a seamless ticketing and check-in experience, we've
                partnered with MYLE - Make Your Life Entertaining! MYLE is an
                “HBCU Alum-Owned" all-in-one AI-based event and entertainment
                platform.
                <br /><br />

                Note: Your tickets will be available on the MYLE App in the
                Account>Tickets section after purchase and you may upload them
                to your phone wallet for quick access into the event. You will
                also receive your tickets via email (check spam folder if not
                immediately received).
                <br /><br />

                (Not required but recommended): Download the MYLE Events App via
                <a
                  href="https://myle.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.myle.com</a
                >
                or find it in your iOS or Google Play Store under MYLE Events.
                Be sure to use referral code TonySalanoEventures" during account
                set-up! This will allow you to be the first to receive future
                event updates through your mobile device. <br /><br />

                The MYLE App offers convenient transportation options including
                Waze, Apple Maps, Google Maps, Uber, and Lyft. It's your
                one-stop solution for a hassle-free travel experience.
                <br /><br />

                To host your events on MYLE, visit

                <a
                  href="https://myle.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.myle.com</a
                >
                or email

                <a href="mailto:hello@myle.com"> hello@myle.com</a> and be sure
                to mention TonySalanoEventures!

                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section ends -->

      <div class="section-heading text-center mt-5">
        <h2
          class=""
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 700;
            font-size: 32px;
            color: black;
          "
        >
          Seating Map
        </h2>
      </div>

      <!-- Map starts -->
      <div id="d2" style="max-width: 900px; margin: auto"></div>
      <!-- Map ends -->

      <div
        class="row align-items-center justify-content-center test"
        style="margin: auto; margin-top: 20px"
      >
        <div
          class="col-md-3 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: #1d5998;
                      color: #1d5998;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                    "
                  >
                    Available For Purchase
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="col-md-3 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: lightgrey;
                      color: lightgrey;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                    "
                  >
                    Reserved
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 32px;
                "
              >
                TICKET OPTIONS
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in individualTick(TicketsResult)"
              >
                <div
                  class="card-header MYLE-green white"
                  style="background: #1d5998; color: white"
                >
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in (tkList[obj.ticketCode] || []).messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn-crimson"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Sold Out"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <br />
            <div class="section-heading text-center">
              <h2
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 32px;
                  color: black;
                "
              >
                Bottle List
              </h2>
            </div>
            <div class="row justify-content-center">
              <div
                class="col-8 col-md-6 justify-content-center d-flex align-items-center"
              >
                <div class="">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/bottle_list_Saloon_Bottle.jpg"
                    alt="Slow Jam Social Event"
                    class="w-100 img-fluid"
                  />
                </div>
              </div>
            </div> -->

      <!-- <br />
            <br />
            <div class="section-heading text-center">
              <h2
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 32px;
                  color: black;
                "
              >
                The Skegee Ball & Parlay Homecoming Soiree
              </h2>
            </div> -->

      <!-- <div class="row justify-content-center">
              <div
                class="col-8 col-md-6 justify-content-center d-flex align-items-center"
              >
                <div class="">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_TheSkegeeBallParlayHomecomingSoiree.jpeg"
                    alt="Slow Jam Social Event"
                    class="w-100 img-fluid"
                  />
                </div>
              </div>
            </div> -->
    </div>
    <div class="row w-100 m-0 promo-section" style="background: #ac0910">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center" style="color: white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import confirmationPopup from "../../components/confirmationPopup";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      metaTags: [],
      metaTitle: "",
      showsuccess: false,
      selectionList: [],
      dict: [],
      availSeating: "",
      required_power_needs: "No",
      selectedSizes: "Extra Small - XS",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      individualsData: [
        "tk_3M5jsuCR5peqw68",
        "tk_1tKUMyzGrQrsudq",
        "tk_lHYeQLPffERdYBn",
        "tk_gAtPwZY5DSBwzRb",
        "tk_MbBemVBFQ9LOJFQ",
        "tk_fsp7DmPw6zsZIDV",
        "tk_mnywWZBOFyXbjNv",
      ],
      groupData: [],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_3M5jsuCR5peqw68: {
          messages: ["Ticket Price: $19.12", "Processing fee of $2.92"],
        },

        tk_1tKUMyzGrQrsudq: {
          messages: ["Ticket Price: $18.67", "Processing fee of $2.89"],
        },

        tk_lHYeQLPffERdYBn: {
          messages: ["Ticket Price: $20", "Processing fee of $2.98"],
        },

        tk_gAtPwZY5DSBwzRb: {
          messages: ["Ticket Price: $30", "Processing fee of $3.65"],
        },

        tk_MbBemVBFQ9LOJFQ: {
          messages: ["Ticket Price: $100", "Processing fee of $8.35"],
        },

        tk_fsp7DmPw6zsZIDV: {
          messages: ["Ticket Price: $125", "Processing fee of $10.03"],
        },

        tk_mnywWZBOFyXbjNv: {
          messages: ["Ticket Price: $150", "Processing fee of $11.7"],
        },
      },

      priceIds: {
        tk_3M5jsuCR5peqw68: "price_1PgrCAD0vTZ4QB9cg1vMWd5t",
        tk_1tKUMyzGrQrsudq: "price_1PgrCAD0vTZ4QB9cBT9Ly9C6",
        tk_lHYeQLPffERdYBn: "price_1PgrCBD0vTZ4QB9ctTaZ1Umk",
        tk_gAtPwZY5DSBwzRb: "price_1PgrCBD0vTZ4QB9ca2ASvIxN",
        tk_MbBemVBFQ9LOJFQ: "price_1PgrCCD0vTZ4QB9cEsgxSb3m",
        tk_fsp7DmPw6zsZIDV: "price_1PgrCCD0vTZ4QB9cUWpD7ypo",
        tk_mnywWZBOFyXbjNv: "price_1PgrCDD0vTZ4QB9cBCbuQUvP",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    StripeCheckout,
    confirmationPopup,
  },

  methods: {
    datamapper() {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };
      const texts = [
        [
          209, 208, 207, 206, 205, 204, 203, 202, 201, 106, 105, 104, 103, 102,
          101,
        ],
        [403, 402, 401, "Bar 2", "Bar 1", 307, 306, 305, 304, 303, 302, 301],
      ];
      const distances = [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 10, 10, 10, 10, 10],
        [20, 20, 20, 35, 55, 80, 80, 80, 80, 80, 80, 80],
      ];

      const heights = [
        [30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30],
        [30, 30, 30, 30, 30, 50, 30, 30, 30, 30, 30, 50],
      ];
      const nodes = [];
      for (let y = 1; y <= distances.length; y++) {
        for (let i = 1; i <= distances[y - 1].length; i++) {
          if (texts[y - 1][i - 1]) {
            nodes.push({
              x: 45 * i + distances[y - 1][i - 1],
              y: 50 * y,
              val: seat[8],
              distance: i * 10,
              ydistance: y * 5,
              selected: false,
              text: texts[y - 1][i - 1],
              availableSeats: [],
              height: heights[y - 1][i - 1],
            });
          }
        }
      }

      return nodes;
    },
    drawseatsInfo(listdata) {
      const dd = {};

      listdata.forEach((l) => {
        l.availableSeating.forEach((l1) => {
          dd[l1.table.split(" ")[1]] = true;
        });
      });
      const svg = window.d3
        .select("div#d2")
        .append("svg")
        .attr("width", "900px")
        .attr("height", "200px");
      this.datamapper(listdata).forEach((v) => {
        const originX = v.x + v.distance;
        const originY = v.y + v.ydistance;
        svg
          .append("rect")
          .attr({
            x: originX,
            y: originY,
            width: v.text == "Bar 2" || v.text == "Bar 1" ? 70 : 50,
            height: v.height,
            fill: v.selected ? "yellow" : "none",
            stroke: "black",
          })
          .attr("fill", dd[v.text] ? "#1d5998" : "#d3d3d3");

        svg
          .append("text")
          .attr({
            x: originX + (v.text < 10 ? 21 : 14),
            y: originY + (v.height === 30 ? 21 : 31),
            "font-size": 15,
            stroke: "red",
          })
          .text(v.text);
      });
    },
    setAffid() {
      let payload = {};
      let affid = null;
      if (
        this.$route &&
        this.$route.query &&
        this.$route.query.affid &&
        this.$route.query.affid.trim().length > 0
      ) {
        affid = this.$route.query.affid.trim();
      }

      if (affid && affid.trim().length > 0) {
        payload = {
          affiliate_code: affid,
        };
      }

      return payload;
    },
    getMetaTagsInfo() {
      const vm = this;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/fetch_meta_info",
        data: {
          eid: "zC6kJyNBrL9ARR",
          src: "WEB_CUSTOM",
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.metaTags = re.data.Result;
            const titleTags = vm.metaTags.find((tag) => tag.name == "name");
            vm.metaTitle = titleTags.content;
          }
        })
        .catch((error) => {
          console.error("There is an error!", error);
        });
    },

    closeModal() {
      this.showsuccess = false;
      this.$refs.confirmmodal12.close();
    },
    uniq(list) {
      const e = {};

      list.forEach((res) => {
        if (!e[res.table]) {
          e[res.table] = true;
        }
      });
      // this.availSeating = Object.keys(e)[0];
      return Object.keys(e);
    },
    listtopush(rr) {
      this.selectionList = [...rr];
    },
    datad1(listdata) {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };
      const texts = [
        [1, 6, 7, 11, 12, 17, 18],
        [2, 5, 8, 10, 13, 16, 19],
        [3, 4, 9, 14, 15, 20],
      ];
      const distances = [0, 0, 20];
      const nodes = [];
      for (let y = 1; y <= 3; y++) {
        for (let i = 1; i <= 7; i++) {
          if (texts[y - 1][i - 1]) {
            nodes.push({
              x: 70 * i + distances[y - 1],
              y: 70 * y,
              val: seat[10],
              distance: i * 10,
              ydistance: y * 10,
              selected: false,
              text: texts[y - 1][i - 1],
              availableSeats: [],
            });
          }
        }
      }

      let recs = [];
      this.dict = {};
      listdata.forEach((r) => {
        recs = [...recs, ...r.availableSeating];
      });

      recs.forEach((r) => {
        const tableRecord = r.table.split("Table ")[1];
        const seatRecord = r.seat.split("Seat ")[1];

        if (!this.dict[tableRecord]) {
          this.dict[tableRecord] = [];
        }

        this.dict[tableRecord].push(Number(seatRecord - 1));
      });
      return nodes;
    },
    individualTick(list) {
      return list.filter((listRecord) =>
        this.individualsData.includes(listRecord.ticketCode)
      );
    },
    groupTickets(list) {
      return list.filter((listRecord) =>
        this.groupData.includes(listRecord.ticketCode)
      );
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });

      // console.log("wwwwwwww  === ");
      if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0]
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].alias;
      } else {
        this.availSeating = "";
      }
      this.redeemData = null;
      this.redeemPriceId = "";
      this.tickcount = 1;
      this.email = "";
      this.fullname = "";
      this.reserveFormAccept = false;
      this.redeempromo = "";

      this.selectionList = [];
      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];

      const e = {};

      this.TicketsResult[idx].availableSeating.forEach((res) => {
        if (!e[res.table]) {
          e[res.table] = true;
        }
      });
      this.availSeating = Object.keys(e)[0];

      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      if (vm.groupData.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        payloadToPush.extraInfo["required_power_needs"] =
          vm.required_power_needs;
      }

      if (vm.availSeating && vm.availSeating.length > 0) {
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: [...this.TicketsResult[this.selected].availableSeating]
            .filter((r) => r.alias.includes(this.availSeating))
            .map((r) => r.alias),
        };
      }
      console.log("dsdsfsdgfg");
      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets");
      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.isSubmitted = false;
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredTheBlueTigerBisonBoogieTickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_3M5jsuCR5peqw68: null,
            tk_1tKUMyzGrQrsudq: null,
            tk_lHYeQLPffERdYBn: null,
            tk_gAtPwZY5DSBwzRb: null,
            tk_MbBemVBFQ9LOJFQ: null,
            tk_fsp7DmPw6zsZIDV: null,
            tk_mnywWZBOFyXbjNv: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
          // console.log(vm.eventTicketResult);
          setTimeout(() => {
            vm.drawseatsInfo(vm.eventTicketResult);
          }, 1000);
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            setTimeout(() => {
              vm.showsuccess = true;
              vm.$refs.confirmmodal12.open();
            }, 1000);
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  beforeMount() {
    // this.getMetaTagsInfo();
  },
  mounted() {
    this.fetchAllTickets();
    //    const id = localStorage.getItem("refsId");
    if (
      //    id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.showsuccess = true;
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    } else if (
      //      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.showsuccess = false;
      setTimeout(() => {
        this.$refs.confirmmodal12.open();
      }, 1000);
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },
  //   metaInfo() {
  //     console.log("HERE");
  //     return {
  //       title: this.metaTitle,
  //       meta: this.metaTags,
  //     };
  //   },

  metaInfo: {
    title: "MYLE - The Big Blue & HU: Official Tennessee State Alumnus Party",
    meta: [
      {
        name: "description",
        content:
          "MYLE - The Big Blue & HU: Official Tennessee State Alumnus Party",
      },
      {
        name: "keywords",
        content:
          "MYLE - The Big Blue & HU: Official Tennessee State Alumnus Party",
      },
      {
        name: "copyright",
        content: "MYLE",
      },
      {
        name: "url",
        content:
          "https://myle.com/the-big-blue-hu-official-tennessee-state-alumnus-party",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

.bolded {
  font-weight: bold;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}

@media (max-width: 622px) {
  #d2 {
    overflow-x: scroll;
  }
}
@media (min-width: 622.1px) {
  #d2 {
    overflow-x: hidden;
  }
}
</style>
