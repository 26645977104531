<template>
  <div>
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div
              class="col-md-6 mb-1"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode !== 'tk_CV9FypVc9e4SYFI'
              "
            >
              <label class="mt-3">Number of Section(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode !== 'tk_CV9FypVc9e4SYFI'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.alias"
                  v-for="seating in uniq(
                    (TicketsResult[selected] || {}).availableSeating
                  )"
                  :value="seating"
                >
                  {{ seating }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div
              class="col-md-12 mt-2"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_CV9FypVc9e4SYFI'
              "
            >
              <doublelist
                @listtopush="listtopush"
                v-if="
                  TicketsResult[selected] &&
                  TicketsResult[selected].availableSeating.length > 0
                "
                :list="(TicketsResult[selected] || {}).availableSeating"
                :limit="(TicketsResult[selected] || {}).allowedPerRequest || 0"
              />
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  (TicketsResult[selected] &&
                  groupData.includes(TicketsResult[selected].ticketCode)
                    ? !required_power_needs.trim().length
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  (TicketsResult[selected] &&
                  TicketsResult[selected].ticketCode == 'tk_CV9FypVc9e4SYFI'
                    ? selectionList.length < 1
                    : false)
                "
                class="btn solid-btn m-auto mr-2"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  background: #d49b0d;
                "
              >
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_TheSkegeeBallParlayHomecomingSoiree.jpeg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background: #d49b0d"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  color: black;
                "
              >
                The Skegee Ball & Parlay Homecoming Soiree
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: black;
                "
              >
                Thursday, October 12th, 2023 <br />
                08:00pm - 03:00am
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: black;
                "
              >
                <a
                  href="https://goo.gl/maps/gF7kDwf95w7tdKJs5"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: black"
                >
                  Buckhead Saloon - 34 Irby Ave NW
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                Join us for The Annual Skegee Ball & Parlay Homecoming Soiree
                hosted by the TU Ball & Parlay Crew that is guaranteed to set TU
                homecoming weekend off at Buckhead's newest hot spot.
                <br /><br />
                Hosted by Too Tuff Tony Salano, Summer Breeze, Big Ted, Harlem
                Woo, D. Marshall, and Bry Bry!!!
                <br /><br />
                Spread the word and let’s Ball & Parlay, the Tuskegee Way!
                <br /><br />

                <span class="bolded">Event Details:</span><br />
                📅 DATE: October 12th, 2023<br />
                ⏰ Time: 8pm-3am<br />
                📍 Location: Buckhead Saloon - 34 Irby Ave NW<br />
                🚗 Valet Parking Available <br />
                🎵 DJ Pro, DJ Tron, DJ Uri House, DJ Cricket<br />

                <br />
                ⚡️ Secure your section now for this EPIC event! Limited
                sections available, so get yours today. ⚡️
                <br />
                <br />

                <span class="bolded">PRICING:</span><br />
                Early Bird - $30 (Limited Time Offer)<br />
                General Admission - $40 <br />
                Event Day - $50

                <br />
                <br />

                <span class="bolded">EARLY BIRD PREMIUM SECTIONS</span> <br />
                Early Bird Premium Sections - Seats 5 ($170 + 1 bottle Min)<br />
                Early Bird Premium Sections - Seats 6 ($210 + 1 bottle Min)<br />
                Premium Sections - Seats 8 ($290 + 1 bottle Min)
                <br />
                <br />
                <span class="bolded">GENERAL PRICING PREMIUM SECTIONS</span>
                <br />
                Premium Sections - Seats 5 ($200 + 1 bottle Min)<br />
                Premium Sections - Seats 6 ($240 + 1 bottle Min)<br />
                Premium Sections - Seats 8 ($320 + 1 bottle Min)

                <br />
                <br />

                <span class="bolded">VIP SECTIONS</span> <br />
                Ball & Parlay Section 1 - 30 Guest ($3000 includes $500 value
                applied toward bottle selection)
                <br />
                Ball & Parlay Section 2 - 20 Guest ($2000 includes $250 value
                applied toward bottle selection)
                <br />
                Ball & Parlay Section 3 - 20 Guest ($2000 includes $250 value
                applied toward bottle selection)
                <br />
                <br />
                Important: Reserved sections must be occupied by a member of the
                party by 11:30pm to avoid forfeiting the reservation.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section ends -->

      <div class="section-heading text-center mt-5">
        <h2
          class=""
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 700;
            font-size: 32px;
            color: black;
          "
        >
          Seating Map
        </h2>
      </div>

      <!-- Map starts -->
      <div
        id="d2"
        style="max-width: 650px; margin: auto; height: auto; margin-top: 50px"
      ></div>
      <!-- Map ends -->

      <div
        class="row align-items-center justify-content-center test"
        style="margin: auto; margin-top: 20px"
      >
        <div
          class="col-md-3 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: #c07f00;
                      color: #c07f00;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                    "
                  >
                    Available For Purchase
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="col-md-3 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: lightgrey;
                      color: lightgrey;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                    "
                  >
                    Reserved
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 32px;
                "
              >
                TICKET OPTIONS
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in individualTick(TicketsResult)"
              >
                <div
                  class="card-header MYLE-green white"
                  style="background: #d49b0d; color: black"
                >
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in (tkList[obj.ticketCode] || []).messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn"
                      id="reserve"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                        background: #d49b0d;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <div class="section-heading text-center">
        <h2
          class=""
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 700;
            font-size: 32px;
            color: black;
          "
        >
          Bottle List
        </h2>
      </div>
      <div class="row justify-content-center">
        <div
          class="col-8 col-md-6 justify-content-center d-flex align-items-center"
        >
          <div class="">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/bottle_list_Saloon_Bottle.jpg"
              alt="Slow Jam Social Event"
              class="w-100 img-fluid"
            />
          </div>
        </div>
      </div>

      <br />
      <br />
      <div class="section-heading text-center">
        <h2
          class=""
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 700;
            font-size: 32px;
            color: black;
          "
        >
          The Skegee Ball & Parlay Homecoming Soiree
        </h2>
      </div>

      <div class="row justify-content-center">
        <div
          class="col-8 col-md-6 justify-content-center d-flex align-items-center"
        >
          <div class="">
            <img
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_TheSkegeeBallParlayHomecomingSoiree.jpeg"
              alt="Slow Jam Social Event"
              class="w-100 img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100 m-0 promo-section" style="background: #d49b0d">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center">Download today and try it for yourself</h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row align-items-center justify-content-between test"
      style="margin: auto; margin-top: 20px"
    >
      <div class="col-lg-3 col-md-3">
        <img
          class="align-middle"
          src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/mock-the-skegee-ball-parlay-homecoming-soiree.png"
          style="width: 100%; max-width: 100%; height: auto"
        />
      </div>
      <div class="col-lg-9 col-md-9">
        <p
          class="mb-2 ml-4"
          style="
            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
            font-size: 18px;
          "
        >
          For a seamless ticketing and check-in experience, we've partnered with
          MYLE - Make Your Life Entertaining! MYLE is "Skegee Alum-Owned"
          all-in-one AI-based event and entertainment platform.

          <br />
          <br />
          Download the MYLE Events App via
          <a href="https://myle.com" target="_blank" rel="noopener noreferrer">
            www.myle.com</a
          >
          or find it in your iOS or Google Play Store under MYLE Events. Don't
          forget to use referral code "MYLER1" during account set-up!

          <br />
          <br />

          The MYLE App offers convenient transportation options, integrating
          Waze, Apple Maps, Google Maps, Uber, and Lyft. It's your one-stop
          solution for a hassle-free travel experience.

          <br />
          <br />
          Note: Your tickets will be available on the MYLE App in the
          Account>Tickets section after purchase. You can easily access them via
          email (check spam if not received) or upload them to your phone wallet
          for quick access into the event.

          <br />
          <br />
          🌟 We can't wait to see you there! Don't miss out on this epic event!
          🌟

          <br />
          <br />
          Parking: In addition to valet, parking is available:<br />
          - Across the Street at The Irby<br />
          - Open street parking<br />
          - Lucy’s on Cains Hill Place NW<br />
          - Gentry Parking Garage (limited)

          <br /><br />

          Note: Once you download the MYLE app, quickly find the event with any
          of these hashtags:
          <br />
          #TUHC23 #BallandParlay #HomecomingSoiree #TUHomecomingSoiree
          #BallandParlaySoiree #SkegeeHomecoming #TUHC #TUHomeComing #Homecoming
          #JustMYLEit #MakeYourLifeEntertaining #MYLE

          <br />
        </p>
      </div>
    </div>

    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      selectionList: [],
      dict: [],
      availSeating: "",
      required_power_needs: "No",
      selectedSizes: "Extra Small - XS",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      individualsData: [
        "tk_NHflAF9XsIqlgJQ",
        "tk_mh6QtPiCxnVVk9P",
        "tk_kdIUic8KCPmAYV9",
        "tk_X8N66vzUKLwaXMG",
        "tk_TioVfkmZZmXysi4",
        "tk_Bw66u6airWxDtYt",
        "tk_KO35I2TSUFCh1NN",
        "tk_nIMETmnv52qI71L",
      ],
      groupData: [],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_NHflAF9XsIqlgJQ: {
          messages: ["Ticket Price: $40", "Processing fee of $4.32"],
        },

        tk_mh6QtPiCxnVVk9P: {
          messages: ["Ticket Price: $170", "Processing fee of $13.04"],
        },

        tk_kdIUic8KCPmAYV9: {
          messages: ["Ticket Price: $210", "Processing fee of $15.72"],
        },

        tk_X8N66vzUKLwaXMG: {
          messages: ["Ticket Price: $290", "Processing fee of $21.09"],
        },

        tk_TioVfkmZZmXysi4: {
          messages: [
            "No Seat Map",
            "Ticket Price: $240",
            "Processing fee of $17.74",
          ],
        },

        tk_Bw66u6airWxDtYt: {
          messages: [
            "Sections 17/18/19",
            "Ticket Price: $3000",
            "Processing fee of $202.86",
          ],
        },

        tk_KO35I2TSUFCh1NN: {
          messages: [
            "Sections S1 & S2",
            "Ticket Price: $2000",
            "Processing fee of $135.78",
          ],
        },

        tk_nIMETmnv52qI71L: {
          messages: [
            "Sections S3 & S4",
            "Ticket Price: $2000",
            "Processing fee of $135.78",
          ],
        },
      },

      priceIds: {
        tk_NHflAF9XsIqlgJQ: "price_1NkMdzD0vTZ4QB9csS0flo1l",
        tk_mh6QtPiCxnVVk9P: "price_1NmLwQD0vTZ4QB9cCt7zFKE7",
        tk_kdIUic8KCPmAYV9: "price_1NkNMeD0vTZ4QB9cEGP4X3iq",
        tk_X8N66vzUKLwaXMG: "price_1NkNMeD0vTZ4QB9c1CJwhkiL",
        tk_TioVfkmZZmXysi4: "price_1NkTM8D0vTZ4QB9cwZSQT8QF",
        tk_Bw66u6airWxDtYt: "price_1NnLAWD0vTZ4QB9cEirH0UdR",
        tk_KO35I2TSUFCh1NN: "price_1NkNMhD0vTZ4QB9cWW73nbPC",
        tk_nIMETmnv52qI71L: "price_1NkNMhD0vTZ4QB9ckWZFcj1S",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    StripeCheckout,
  },

  methods: {
    uniq(list) {
      const e = {};

      list.forEach((res) => {
        if (!e[res.table]) {
          e[res.table] = true;
        }
      });
      // this.availSeating = Object.keys(e)[0];
      return Object.keys(e);
    },
    listtopush(rr) {
      this.selectionList = [...rr];
    },
    datad1(listdata) {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };
      const texts = [
        [1, 6, 7, 11, 12, 17, 18],
        [2, 5, 8, 10, 13, 16, 19],
        [3, 4, 9, 14, 15, 20],
      ];
      const distances = [0, 0, 20];
      const nodes = [];
      for (let y = 1; y <= 3; y++) {
        for (let i = 1; i <= 7; i++) {
          if (texts[y - 1][i - 1]) {
            nodes.push({
              x: 70 * i + distances[y - 1],
              y: 70 * y,
              val: seat[10],
              distance: i * 10,
              ydistance: y * 10,
              selected: false,
              text: texts[y - 1][i - 1],
              availableSeats: [],
            });
          }
        }
      }

      let recs = [];
      this.dict = {};
      listdata.forEach((r) => {
        recs = [...recs, ...r.availableSeating];
      });

      recs.forEach((r) => {
        const tableRecord = r.table.split("Table ")[1];
        const seatRecord = r.seat.split("Seat ")[1];

        if (!this.dict[tableRecord]) {
          this.dict[tableRecord] = [];
        }

        this.dict[tableRecord].push(Number(seatRecord - 1));
      });
      return nodes;
    },
    drawseatsd2(list1) {
      console.log(list1);
      const dd = {};
      list1.forEach((l) => {
        l.availableSeating.forEach((l1) => {
          const ww = l1.table.split(" ");
          console.log(l1.table);

          if (l.ticketCode == "tk_kdIUic8KCPmAYV9") {
            if (
              ww[ww.length - 1] == "6" ||
              ww[ww.length - 1] == "7" ||
              ww[ww.length - 1] == "8" ||
              ww[ww.length - 1] == "9"
            ) {
              dd[ww[ww.length - 1] + "A"] = true;
              dd[ww[ww.length - 1] + "B"] = true;
            } else {
              dd[ww[ww.length - 1]] = true;
            }
          } else if (l.ticketCode == "tk_Bw66u6airWxDtYt") {
            dd["17"] = true;
            dd["18"] = true;
            dd["19"] = true;
          } else if (l.ticketCode == "tk_KO35I2TSUFCh1NN") {
            dd["S1"] = true;
            dd["S2"] = true;
          } else if (l.ticketCode == "tk_nIMETmnv52qI71L") {
            dd["S3"] = true;
            dd["S4"] = true;
          } else if (l1.table == "Section " + ww[ww.length - 1]) {
            dd[ww[ww.length - 1]] = true;
          }
        });
      });

      console.log(Object.keys(dd));
      const svg = window.d3
        .select("div#d2")
        .append("svg")
        .attr("width", "650px")
        .attr("height", "500px");
      const list = [
        [
          {
            label: "Bar",
            width: 80,
            height: 40,
          },
          {
            label: "1",
            width: 50,
            height: 50,
          },
          {
            label: "2",
            width: 50,
            height: 50,
          },
          {
            label: "Floor",
            width: 80,
            height: 40,
          },
          {
            label: "3",
            width: 50,
            height: 50,
          },
          {
            label: "4",
            width: 50,
            height: 50,
          },
          null,
          null,
          {
            label: "S3",
            width: 50,
            height: 50,
            x: 20,
          },
          {
            label: "DJ",
            width: 50,
            height: 50,
          },
        ],
        [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          {
            label: "Stage",
            width: 80,
            height: 30,
          },
          {
            label: "S4",
            width: 50,
            height: 50,
          },
        ],

        [
          {
            label: "S2",
            width: 50,
            height: 50,
          },
          null,
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "6B",
            width: 50,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "7B",
            width: 50,
            height: 50,
          },
          {
            label: "8B",
            width: 50,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "9B",
            width: 50,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "11",
            width: 50,
            height: 50,
          },
        ],

        [
          {
            label: "Stage",
            width: 80,
            height: 50,
          },
          {
            label: "5",
            width: 20,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "6A",
            width: 50,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "7A",
            width: 50,
            height: 50,
          },
          {
            label: "8A",
            width: 50,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "9A",
            width: 50,
            height: 50,
          },
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          {
            label: "10",
            width: 50,
            height: 50,
          },
          {
            label: "12",
            width: 50,
            height: 50,
            y: 15,
          },
          {
            label: "13",
            width: 50,
            height: 50,
            y: -5,
          },
        ],

        [
          {
            label: "S1",
            width: 50,
            height: 50,
          },
          null,
          null,
          null,
          null,
          {
            label: "VIP",
            width: 80,
            height: 50,
          },
          null,
          null,
          null,
          {
            label: "Floor",
            width: 80,
            height: 50,
          },
        ],

        [
          {
            label: "",
            width: 20,
            height: 50,
            fill: "black",
          },
          null,
          null,
          null,
          null,
          null,
          null,
          {
            label: "17",
            width: 50,
            height: 50,
            y: 10,
          },
          {
            label: "14",
            width: 50,
            height: 50,
          },
          {
            label: "15",
            width: 50,
            height: 50,
          },
          {
            label: "16",
            width: 80,
            height: 50,
          },
        ],

        [
          null,
          null,
          {
            label: "Owner",
            width: 90,
            height: 50,
          },
          {
            label: "19",
            width: 50,
            height: 50,
          },
          {
            label: "18",
            width: 50,
            height: 50,
          },
          {
            label: "17",
            width: 50,
            height: 50,
          },
        ],
      ];

      let x = 0;
      let y = 20;
      list.forEach((row) => {
        row.forEach((item) => {
          if (item) {
            svg
              .append("rect")
              .attr("width", item.width)
              .attr("height", item.height)
              .attr(
                "transform",
                "translate(" +
                  (x +
                    (item.label == "S2" || item.label == "S1" ? 35 : 0) -
                    (item.x || 0)) +
                  "," +
                  (y -
                    (item.label == "S2" || item.label == "S1" ? 15 : 0) -
                    (item.y || 0)) +
                  ") rotate(" +
                  (item.label == "S2" || item.label == "S1" ? 45 : 0) +
                  ")"
              )
              //  .attr("x", x - (item.x || 0))
              //  .attr("y", y - (item.y || 0))
              .attr(
                "fill",
                !item.fill
                  ? item.label && dd[item.label]
                    ? "#d49b0d"
                    : "none"
                  : item.fill
              )
              .attr(
                "fill",
                !item.fill
                  ? item.label && dd[item.label]
                    ? "#d49b0d"
                    : ["Bar", "Floor", "VIP", "DJ", "Stage", "Owner"].includes(
                        item.label
                      )
                    ? "none"
                    : "lightgrey"
                  : item.fill
              )
              .attr(
                "stroke",
                ["Bar", "Floor", "VIP", "DJ", "Stage", "Owner"].includes(
                  item.label
                )
                  ? "none"
                  : "black"
              );
            //      .style("transform", (item.label == 'S2' || item.label == 'S1') ? "translate(50deg, 50deg) rotate(45deg)" : "none");
            svg
              .append("text")
              .attr(
                "x",
                x +
                  (item.label == "S2" || item.label == "S1" ? 10 : 0) -
                  (item.x || 0) +
                  item.width / 3
              )
              .attr("y", y - (item.y || 0) + item.height / 1.7)
              .text(item.label)
              .attr("fill", "none")
              .attr("stroke", "black");
          }

          x += (item ? item.width : 50) + 10;
        });

        x = 0;
        y += 60;
      });
    },
    individualTick(list) {
      return list.filter((listRecord) =>
        this.individualsData.includes(listRecord.ticketCode)
      );
    },
    groupTickets(list) {
      return list.filter((listRecord) =>
        this.groupData.includes(listRecord.ticketCode)
      );
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });

      console.log("wwwwwwww  === ");
      if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0]
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].alias;
      } else {
        this.availSeating = "";
      }
      this.selectionList = [];
      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];

      const e = {};

      this.TicketsResult[idx].availableSeating.forEach((res) => {
        if (!e[res.table]) {
          e[res.table] = true;
        }
      });
      this.availSeating = Object.keys(e)[0];

      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      if (vm.groupData.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        payloadToPush.extraInfo["required_power_needs"] =
          vm.required_power_needs;
      }

      if (vm.availSeating && vm.availSeating.length > 0) {
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: this.availSeating,
        };
      }

      if (vm.TicketsResult[vm.selected].ticketCode == "tk_CV9FypVc9e4SYFI") {
        const addionalSelection = [];
        this.selectionList.forEach((r) => {
          addionalSelection.push(r.alias);
        });
        payloadToPush.totalTickets = addionalSelection.length;
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: addionalSelection,
        };
      }

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets");
      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.isSubmitted = false;
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredSkegeeBallParlayHomecomingSoiree_eventtickets"
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_NHflAF9XsIqlgJQ: null,
            tk_mh6QtPiCxnVVk9P: null,
            tk_kdIUic8KCPmAYV9: null,
            tk_X8N66vzUKLwaXMG: null,
            tk_TioVfkmZZmXysi4: null,
            tk_Bw66u6airWxDtYt: null,
            tk_KO35I2TSUFCh1NN: null,
            tk_nIMETmnv52qI71L: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
          console.log(vm.eventTicketResult);
          vm.drawseatsd2(vm.eventTicketResult);
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - The Skegee Ball & Parlay Homecoming Soiree - Shifted",

    meta: [
      {
        name: "description",
        content: "MYLE - The Skegee Ball & Parlay Homecoming Soiree - Shifted",
      },

      {
        name: "keywords",
        content: "MYLE - The Skegee Ball & Parlay Homecoming Soiree - Shifted",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/the-skegee-ball-parlay-homecoming-soiree-shifted",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

.bolded {
  font-weight: bold;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}

@media (max-width: 622px) {
  #d2 {
    overflow-x: scroll;
  }
}
@media (min-width: 622.1px) {
  #d2 {
    overflow-x: hidden;
  }
}
</style>
