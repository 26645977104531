<template>
  <div>
    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_GEAA_BI_ANNUAL_KAPPA_RETREAT.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="download-content">
            <p
              class="lead-heading text-center mb-1"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 800;
                font-size: 40px;
              "
            >
              GEAA BI-ANNUAL KAPPA RETREAT
            </p>
          </div>
          <hr />
          <div class="col-md-12">
            <div class="download-content">
              <p
                class="lead-heading mb-5"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 20px;
                "
              >
                The Gamma Epsilon Alumni Association celebrate our 2022
                Bi-Annual Kappa Retreat in Atlanta, Ga from July 22nd thru July
                24th, 2022.
                <br />

                We welcome you to join us for a weekend of fellowship and
                fraternizing.
                <br />
                Family and Friends are welcome to all public events.
              </p>
            </div>
          </div>

          <div class="col-md-12">
            <div class="download-content">
              <p
                class="lead-heading text-center mb-0"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 30px;
                "
              >
                Schedule of Activities
              </p>
              <hr />
              <br />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="container">
          <p
            class="lead-heading text-center"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 30px;
            "
          >
            - Thursday Activity -
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-lg-3 align-items-center"></div>

        <div class="col-md-6 col-lg-6 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            GEAA Welcome to Atlanta Nupe Fellowship
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            6pm-12pm
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            The Monticello
          </p>

          <div
            class="download-btn mt-4 text-center animate__animated animate__fadeIn"
          >
            <a
              href=" https://myle.com/event.php?id=SuK88vnFmxARha"
              class="btn solid-btn mr-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mobile</a
            >
            <a
              href="https://web.myle.com/e/Welcome%20To%20Atlanta%20Nupe%20Fellowship/SuK88vnFmxARha"
              class="btn solid-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              Web App</a
            >
          </div>
        </div>
        <div class="col-md-3 col-lg-3 align-items-center"></div>
      </div>
      <hr />

      <div class="row">
        <div class="container text-center">
          <p
            class="lead-heading"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 30px;
            "
          >
            - Friday Activities -
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-lg-6 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            GEAA Golf Tournament
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            8am-1pm
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            City Club Marietta
          </p>

          <div
            class="download-btn mt-4 text-center animate__animated animate__fadeIn"
          >
            <a
              href=" https://myle.com/event.php?id=grcj6ZzT5suH4Q"
              class="btn solid-btn mr-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mobile</a
            >
            <a
              href="https://web.myle.com/e/GEAA%20Golf%20Tournament/grcj6ZzT5suH4Q"
              class="btn solid-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              Web App</a
            >
          </div>
        </div>

        <div class="col-md-6 col-lg-6 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            GEAA Business Meeting & Fraternizer
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            6pm-Until
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            Registration @ 5pm
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            Private Event - Passcode will be provided to access details
          </p>

          <div
            class="download-btn mt-4 text-center animate__animated animate__fadeIn"
          >
            <a
              href="https://myle.com/event.php?id=H7LTCxlu3UMHT7"
              class="btn solid-btn mr-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mobile</a
            >
            <a
              href="https://web.myle.com/e/GEAA%20Business%20Mtg%20and%20Fraternizer/H7LTCxlu3UMHT7"
              class="btn solid-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              Web App</a
            >
          </div>
        </div>
      </div>
      <hr />

      <div class="row">
        <div class="container text-center">
          <p
            class="lead-heading"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 30px;
            "
          >
            - Saturday Activities -
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-6 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            GEAA Retreat Cookout
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            11am-5pm
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            Private Event - Passcode will be provided to access details
          </p>

          <div
            class="download-btn mt-4 text-center animate__animated animate__fadeIn"
          >
            <a
              href="https://myle.com/event.php?id=gdgukDLvDqrk0z"
              class="btn solid-btn mr-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mobile</a
            >
            <a
              href="https://web.myle.com/e/GEAA%20Cookout/gdgukDLvDqrk0z"
              class="btn solid-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              Web App</a
            >
          </div>
        </div>

        <div class="col-md-6 col-lg-6 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            GEAA Celebration After Party
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            8:11pm-12:11am
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            Spice House - Midtown
          </p>

          <div
            class="download-btn mt-4 text-center animate__animated animate__fadeIn"
          >
            <a
              href="https://myle.com/event.php?id=BAe0gPETHzgXm1"
              class="btn solid-btn mr-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mobile</a
            >
            <a
              href="https://web.myle.com/e/GEAA%20Celebration%20After%20Party/BAe0gPETHzgXm1"
              class="btn solid-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              Web App</a
            >
          </div>
        </div>
      </div>
      <hr />

      <div class="row">
        <div class="container text-center">
          <p
            class="lead-heading"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 700;
              font-size: 30px;
            "
          >
            - Sunday Activity -
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-lg-3 align-items-center"></div>

        <div class="col-md-6 col-lg-6 align-items-center">
          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 22px;
            "
          >
            Farewell Breakfast
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            9am-12pm
          </p>

          <p
            class="text-center lead-heading ml-3 mb-0"
            style="
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 16px;
            "
          >
            Renaissance Hotel
          </p>

          <div
            class="download-btn mt-4 text-center animate__animated animate__fadeIn"
          >
            <a
              href="https://myle.com/event.php?id=BPnUirCMTwRKr4"
              class="btn solid-btn mr-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mobile</a
            >
            <a
              href="https://web.myle.com/e/Farewell%20Breakfast/BPnUirCMTwRKr4"
              class="btn solid-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              Web App</a
            >
          </div>
        </div>
        <div class="col-md-3 col-lg-3 align-items-center"></div>
      </div>
      <hr />
      <div class="row mt-5">
        <div class="container">
          <div class="col-md-12 col-lg-12 col-sm-12">
            <h6
              class="mb-3 font-bold"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                font-size: 18px;
              "
            >
              DOWNLOAD THE MYLE APP TO ACCESS ALL EVENTS:
            </h6>

            <h6
              class="mt-4 mb-2 font-bold"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              How to Set Up MYLE Account
            </h6>

            <h8
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 15px;
              "
              >1. Visit the iOS or Google Play Store or Tap buttons below </h8
            ><br />

            <h8
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 15px;
              "
              >2. Search MYLE Events (you will see events curated for you) </h8
            ><br />
            <h8
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 15px;
              "
              >3. Download the app </h8
            ><br />
            <h8
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 15px;
              "
            >
              4. Tap Create Account at the bottom of the page </h8
            ><br />

            <h8
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 15px;
              "
            >
              5. Create New MYLE Account (remember your credentials)</h8
            ><br />

            <h8
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 15px;
              "
            >
              6. Referral Code - Enter GEAA </h8
            ><br />

            <h8
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 15px;
              "
            >
              7. When the OTP code is sent to your email, check your spam folder
              if necessary </h8
            ><br />

            <h8
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 15px;
              "
            >
              8. Once your account is set up, search GEAA for non-private
              functions. </h8
            ><br /><br />

            <h6
              class="mt-4 mb-2 font-bold"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 16px;
              "
            >
              Note: A private event passcode will be provided through the
              GroupMe chat. Also, you will receive a custom link that will
              provide you with access to all retreat activities, which are
              available on the MYLE App.
            </h6>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100 m-0 promo-section MYLE-lite-green">
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center text-white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";

// defining component state
export default {
  name: "newyear",
  data() {
    return {};
  },
  components: {
    NavBar,
    SiteFooter,
  },

  // methods: {},
  // created() {},
  // mounted() {},
  // computed: {},

  metaInfo: {
    title: "MYLE - GEAA BI-ANNUAL KAPPA RETREAT",

    meta: [
      {
        name: "description",
        content: "GEAA BI-ANNUAL KAPPA RETREAT",
      },

      {
        name: "keywords",
        content: "GEAA BI-ANNUAL KAPPA RETREAT",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/geaa-bi-annual-kappa-retreat",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
</style>
